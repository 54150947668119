<template>
  <div>
    <div class="sd-container">
      <img
          class="header-image"
          v-if="isDark"
          loading="lazy"
          src="@/assets/images/sd_iso_mobile_dark.webp"
      />
      <img
          class="header-image"
          v-else
          loading="lazy"
          src="@/assets/images/sd_iso_mobile.webp"
      />
      <div
          class="content-container"
          :style="{ background: myColors.background }"
      >
        <SubtitleBody
            :title="sentinelContent.name"
            :description="sentinelContent.details.description.description"
        />
        <Role :roles="sentinelContent.details.role"/>
        <SubtitleList
            subtitle="Business Goals"
            :list="sentinelContent.details.businessGoals"
        />
        <div class="section-break"></div>
        <SubtitleBody
            title="UX process"
            :subtitle="sentinelContent.details.uxProcess.processes[0].title"
            :description="
            sentinelContent.details.uxProcess.processes[0].description
          "
        />
        <div class="three-col-img">
          <Figure caption="Super-user Sandra">
            <div class="fig sandra"></div>
          </Figure>
          <Figure caption="Maybe-digital David">
            <div class="fig david"></div>
          </Figure>
          <Figure caption="Media-savvy Meg">
            <div class="fig meg"></div>
          </Figure>
        </div>
        <SubtitleBody
            :subtitle="sentinelContent.details.uxProcess.processes[1].title"
            :description="
            sentinelContent.details.uxProcess.processes[1].description
          "
        />
        <figure caption="Sentinel reader story map">
          <div class="fig storyMap"></div>
        </figure>

        <BodyBullets
            :body="
            sentinelContent.details.uxProcess.processes[2].listDetails[0]
              .description
          "
            :list="
            sentinelContent.details.uxProcess.processes[2].listDetails[0].list
          "
        />

        <SubtitleBody
            title="Clean UI"
            :subtitle="
            sentinelContent
            .details.uxProcess.processes[3].listDetails[0].title
          "
            :description="
            sentinelContent.details.uxProcess.processes[3].listDetails[0]
              .description
          "
        />
        <div class="section-break"></div>
        <Figure caption="Article page on desktop">
          <div class="fig article"></div>
        </Figure>
        <div class="section-break"></div>
        <!-- <VideoPlayer /> -->
        <InterActiveVideo
            :video="readingExp"
            caption="Article page on desktop"
            video-width="calc(100vw - 4rem)"
            is-desktop="false" cover-height="16.5rem"/>
        <div class="section-break"></div>
        <SubtitleBody
            :subtitle="
            sentinelContent
            .details
            .uxProcess
            .processes[3]
            .listDetails[1].title
          "
            :description="
            sentinelContent.details.uxProcess.processes[3].listDetails[1]
              .description
          "
        />
        <div class="section-break"></div>
        <Figure
            caption="Card view containing information about an issue"
            textAlignment="left"
        >
          <div class="fig card"></div>
        </Figure>
        <div class="section-break"></div>
        <SubtitleBody
            :subtitle="
            sentinelContent.details.uxProcess.processes[3].listDetails[2].title
          "
            :description="
            sentinelContent.details.uxProcess.processes[3].listDetails[2]
              .description
          "
        />
        <div class="section-break"></div>
        <Figure caption="Audio landing page" textAlignment="left">
          <div class="fig audio-landing-page"></div>
        </Figure>
        <div class="section-break"></div>
        <SubtitleBody
            :subtitle="sentinelContent.details.uxProcess.processes[4].title"
            :description="
            sentinelContent.details.uxProcess.processes[4].description
          "
        />
        <div class="section-break"></div>
        <div class="figures">
          <Figure
              caption="Table of contents page. Each card represents a piece of content from the issue."
              textAlignment="left"
          >
            <div class="fig toc"></div>
          </Figure>
          <Figure
              caption="Table of contents page. Each card represents a piece of content from the issue."
              textAlignment="left"
          >
            <div class="fig web-excl"></div>
          </Figure>
        </div>
        <div class="section-break"></div>
        <SubtitleBody
            :subtitle="sentinelContent.details.uxProcess.processes[5].title"
            :description="
            sentinelContent.details.uxProcess.processes[5].description
          "
        />
        <div class="section-break"></div>
        <div class="figures">
          <Figure caption="Home page on mobile" textAlignment="left">
            <div class="fig toc-mobile"></div>
          </Figure>
          <Figure caption="Web exclusives on mobile" textAlignment="left">
            <div class="fig web-excl-mobile"></div>
          </Figure>
          <Figure caption="Audio modal console on mobile" textAlignment="left">
            <!-- <img width="191px" loading="lazy" src="@/assets/images/audio_modal.webp" /> -->
            <div class="fig audio-modal"></div>
          </Figure>
        </div>
        <div class="section-break"></div>
        <SubtitleBody
            :title="sentinelContent.details.verification.title"
            titleType="alt"
            :description="sentinelContent.details.verification.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {store} from "@/store/store.js";
import {colors} from "@/core/colors.js";
import {written} from "@/core/data/written_data.ts";
import SubtitleBody from "@/components/info/desktop/Subtitle_Body.vue";
import SubtitleList from "@/components/info/desktop/Subtitle_List.vue";
import BodyBullets from "@/components/info/desktop/Body_Bullets.vue";
import Figure from "@/components/info/desktop/Figure.vue";
import Role from "@/components/info/desktop/Role.vue";
import VideoPlayer from "@/components/info/VideoPlayer.vue";
import InterActiveVideo from "@/components/info/InteractionVideo"
import ReadingExp from "@/assets/videos/article.mp4"

export default {
  name: "SentinelDigital",
  components: {
    SubtitleBody,
    SubtitleList,
    Role,
    Figure,
    BodyBullets,
    VideoPlayer,
    InterActiveVideo
  },
  data() {
    return {
      label: "explore",
      hasHovered: false,
      readingExp: ReadingExp
    };
  },
  computed: {
    isDesktop: function () {
      return window.innerWidth < 480 ? false : true;
    },
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    sentinelContent: function () {
      return written.projects.sentinel;
    },
    headerImage: function () {
      if (this.isDark) {
        return "@/assets/images/sd_iso_mobile_dark.webp";
      } else {
        return "@/assets/images/sd_iso_mobile.webp";
      }
    },
  },
  methods: {
    renderHover: function () {
      this.hasHovered = true;
    },
    renderNormal: function () {
      this.hasHovered = false;
    },
    scrollToTop: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.storyMap {
  background: url("../../../assets/images/story_map.webp") no-repeat;
}

.sandra {
  background: url("../../../assets/images/persona_1.webp") no-repeat;
}

.david {
  background: url("../../../assets/images/persona_2.webp") no-repeat;
}

.meg {
  background: url("../../../assets/images/persona_3.webp") no-repeat;
}

.article {
  background: url("../../../assets/images/article_page.webp") no-repeat;
}

.card {
  background: url("../../../assets/images/vertical_thumbnail_header_card.webp") no-repeat;
  background-position: center;
  width: 25rem;
  margin: 0 0 2rem 0 !important;
}

.web-excl {
  background: url("../../../assets/images/web_exclusives.webp") no-repeat;
  height: 53rem !important;
  background-size: 100% auto;
}

.toc {
  background: url("../../../assets/images/toc.webp") no-repeat;
  height: 98rem !important;
  background-size: 100% auto;
}

.audio-landing-page {
  background: url("../../../assets/images/audio_landing_page.webp") no-repeat;
  height: 41rem !important;
  background-size: 100% auto;
}

.toc-mobile {
  background: url("../../../assets/images/toc_mobile.webp") no-repeat;
  height: 41rem !important;
  background-size: 100% auto;
}

.web-excl-mobile {
  background: url("../../../assets/images/web_exclusives_mobile.webp") no-repeat;
  height: 41rem !important;
  background-size: 100% auto;
}

.audio-modal {
  background: url("../../../assets/images/audio_modal.webp") no-repeat;
  height: 41rem !important;
  background-size: 100% auto;
}

.figures {
  display: flex;
  flex-flow: column !important;
  padding: 0 !important;
  width: 100vw;
}

.fig {
  height: 20rem;
  background-size: contain;
  background-position: center;
  width: 100vw;
}

.figure {
  padding: 0 !important;
  display: grid !important;
  grid-template-rows: auto 2rem !important;
  place-items: start;
}

.sd-container {
  width: 100vw;
  display: grid;
  grid-template-rows: auto;
  place-items: center;

  /* margin: 3rem 0 100% 0;
  display: flex;
  flex-flow: column; */
}

.container {
  width: auto !important;
}

.header-image {
  z-index: 0;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
}

.persona-image {
  position: relative !important;
  height: 17rem;
}

.content-container {
  width: 100vw;
  margin: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto;
  place-items: start;
}

.content-container > div {
  padding: 0 2rem;
}

.three-col-img {
  padding: 0 !important;
}
</style>
