var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sd-container"},[(_vm.isDark)?_c('img',{staticClass:"header-image",attrs:{"loading":"lazy","src":require("@/assets/images/sd_iso_mobile_dark.webp")}}):_c('img',{staticClass:"header-image",attrs:{"loading":"lazy","src":require("@/assets/images/sd_iso_mobile.webp")}}),_c('div',{staticClass:"content-container",style:({ background: _vm.myColors.background })},[_c('SubtitleBody',{attrs:{"title":_vm.sentinelContent.name,"description":_vm.sentinelContent.details.description.description}}),_c('Role',{attrs:{"roles":_vm.sentinelContent.details.role}}),_c('SubtitleList',{attrs:{"subtitle":"Business Goals","list":_vm.sentinelContent.details.businessGoals}}),_c('div',{staticClass:"section-break"}),_c('SubtitleBody',{attrs:{"title":"UX process","subtitle":_vm.sentinelContent.details.uxProcess.processes[0].title,"description":_vm.sentinelContent.details.uxProcess.processes[0].description}}),_c('div',{staticClass:"three-col-img"},[_c('Figure',{attrs:{"caption":"Super-user Sandra"}},[_c('div',{staticClass:"fig sandra"})]),_c('Figure',{attrs:{"caption":"Maybe-digital David"}},[_c('div',{staticClass:"fig david"})]),_c('Figure',{attrs:{"caption":"Media-savvy Meg"}},[_c('div',{staticClass:"fig meg"})])],1),_c('SubtitleBody',{attrs:{"subtitle":_vm.sentinelContent.details.uxProcess.processes[1].title,"description":_vm.sentinelContent.details.uxProcess.processes[1].description}}),_vm._m(0),_c('BodyBullets',{attrs:{"body":_vm.sentinelContent.details.uxProcess.processes[2].listDetails[0]
            .description,"list":_vm.sentinelContent.details.uxProcess.processes[2].listDetails[0].list}}),_c('SubtitleBody',{attrs:{"title":"Clean UI","subtitle":_vm.sentinelContent
          .details.uxProcess.processes[3].listDetails[0].title,"description":_vm.sentinelContent.details.uxProcess.processes[3].listDetails[0]
            .description}}),_c('div',{staticClass:"section-break"}),_c('Figure',{attrs:{"caption":"Article page on desktop"}},[_c('div',{staticClass:"fig article"})]),_c('div',{staticClass:"section-break"}),_c('InterActiveVideo',{attrs:{"video":_vm.readingExp,"caption":"Article page on desktop","video-width":"calc(100vw - 4rem)","is-desktop":"false","cover-height":"16.5rem"}}),_c('div',{staticClass:"section-break"}),_c('SubtitleBody',{attrs:{"subtitle":_vm.sentinelContent
          .details
          .uxProcess
          .processes[3]
          .listDetails[1].title,"description":_vm.sentinelContent.details.uxProcess.processes[3].listDetails[1]
            .description}}),_c('div',{staticClass:"section-break"}),_c('Figure',{attrs:{"caption":"Card view containing information about an issue","textAlignment":"left"}},[_c('div',{staticClass:"fig card"})]),_c('div',{staticClass:"section-break"}),_c('SubtitleBody',{attrs:{"subtitle":_vm.sentinelContent.details.uxProcess.processes[3].listDetails[2].title,"description":_vm.sentinelContent.details.uxProcess.processes[3].listDetails[2]
            .description}}),_c('div',{staticClass:"section-break"}),_c('Figure',{attrs:{"caption":"Audio landing page","textAlignment":"left"}},[_c('div',{staticClass:"fig audio-landing-page"})]),_c('div',{staticClass:"section-break"}),_c('SubtitleBody',{attrs:{"subtitle":_vm.sentinelContent.details.uxProcess.processes[4].title,"description":_vm.sentinelContent.details.uxProcess.processes[4].description}}),_c('div',{staticClass:"section-break"}),_c('div',{staticClass:"figures"},[_c('Figure',{attrs:{"caption":"Table of contents page. Each card represents a piece of content from the issue.","textAlignment":"left"}},[_c('div',{staticClass:"fig toc"})]),_c('Figure',{attrs:{"caption":"Table of contents page. Each card represents a piece of content from the issue.","textAlignment":"left"}},[_c('div',{staticClass:"fig web-excl"})])],1),_c('div',{staticClass:"section-break"}),_c('SubtitleBody',{attrs:{"subtitle":_vm.sentinelContent.details.uxProcess.processes[5].title,"description":_vm.sentinelContent.details.uxProcess.processes[5].description}}),_c('div',{staticClass:"section-break"}),_c('div',{staticClass:"figures"},[_c('Figure',{attrs:{"caption":"Home page on mobile","textAlignment":"left"}},[_c('div',{staticClass:"fig toc-mobile"})]),_c('Figure',{attrs:{"caption":"Web exclusives on mobile","textAlignment":"left"}},[_c('div',{staticClass:"fig web-excl-mobile"})]),_c('Figure',{attrs:{"caption":"Audio modal console on mobile","textAlignment":"left"}},[_c('div',{staticClass:"fig audio-modal"})])],1),_c('div',{staticClass:"section-break"}),_c('SubtitleBody',{attrs:{"title":_vm.sentinelContent.details.verification.title,"titleType":"alt","description":_vm.sentinelContent.details.verification.description}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('figure',{attrs:{"caption":"Sentinel reader story map"}},[_c('div',{staticClass:"fig storyMap"})])
}]

export { render, staticRenderFns }