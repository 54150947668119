<template>
  <div
    :style="{ background: myColors.foregroundAccentActive }"
    class="role-container"
  >
    <p :style="{ color: myColors.main }" class="role-header">Role</p>
    <p
      v-for="(item, index) in roles"
      :key="index"
      :style="{ color: myColors.foreground }"
    >
      {{ item }}
    </p>
  </div>
</template>

<script>
import { colors } from "@/core/colors.js";

export default {
  props: {
    roles: {
      default: [],
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
  },
};
</script>

<style scoped>
.role-container {
  inline-size: fit-content;
  box-sizing: border-box;
  padding-block: 1rem;
  padding-inline: 1rem 3rem;
  border-radius: 0.5rem;
  text-align: left;
  display: grid;
  gap: 0.5rem;
}

@media (max-width: 480px) {
  .role-container {
  }
}
</style>
